import React from "react"
import "../assets/styles/page/terms.scss"
import Header from "../components/header"
import { Link } from "gatsby"

const terms = () => {
  return (
    <div>
      <div className="terms--page--wrapper">
        <div className="header--container">
          <Header isDarkMode={false} />
        </div>
        <div className="terms--section--container">
          <div className="terms--inner">
            <div className="heading--text">
              <h1>Brooi's Terms of Service</h1>
              <p>Last Updated:</p>
            </div>
            <div className="terms--content">
              <h3 className="header--three--wrap">Welcome to Brooi!</h3>
              <p className="paragraph--wrapper">
                You are welcome to Brooi, Thank you for choosing Brooi. Brooi is your one-stop shop to short-letting. We
                are a company that provides an online marketplace with listings for short-lets, lodgings and primary
                homestay rentals for vacations and parties. We are your trusted bridge to shortlet owners. We connect
                homeowners with individuals, families and tourists looking for accommodations that are more like homes
                than hotels and apartment for parties. Brooi gets you home away from home.
              </p>
              <p className="paragraph--wrapper">
                These Terms of Service constitute a binding legal agreement between you (“the <span>User</span>”, “
                <span>You</span>” “<span>Your</span>”) and Alvonant Limited (“<span>Brooi</span>”, “<span>We</span>”, “
                <span>Us</span>”, “<span>Our</span>”, the “<span>Site</span>”). Please read carefully the terms and
                conditions contained in this Terms of Service Agreement (“<span>Terms</span>” or “<span>Agreement</span>
                ”) because these Terms govern your use of and access to our website, all related subdomains, Apps, and
                the functionality enabling users to publish, offer, search for and book our services through our
                website, App or related platforms (together the “<span>Platform(s)</span>”). Users who publish and
                access to a financial account to retrieve financial account data and receive related data enrichment
                services or initiating payment transactions through a third-party application, product or service used
                by User, delivered via application programming interfaces, software development kits, and any other
                services, content, tools, and features as made available by Brooi occasionally.
              </p>
              <p className="paragraph--wrapper">
                By accessing and using our website, Apps, or by clicking “I agree” upon signing up and creating an
                account, you agree to be bound by the terms and conditions of this Agreement. Brooi recommends that you
                thoroughly review this Agreement, as these terms may be updated occasionally by Brooi, before accessing
                or attempting to use our Services and during the use of our Services. Bear in mind that we reserve the
                rights to revise, modify, amend, or alter these Terms without prior notice, occasionally. Users are
                advised to periodically check these Terms of Service for any subsequent changes.
              </p>
              <p className="paragraph--wrapper">
                User must not access our website or use our Services if User doesn't agree to all of the terms and
                provisions of this Agreement, otherwise, your continued use of this website constitutes your acceptance
                of the terms in this agreement (including after subsequent changes have been effected). A copy of these
                Terms may be downloaded, saved, and printed for your reference.
              </p>
              <p className="paragraph--wrapper">
                The website is owned and operated by Alvonant Limited, a company resident and incorporated under the
                laws of the Federal Republic of Nigeria.
              </p>
              <ul>
                <li>
                  <h3 className="header--three--wrap">Definitions</h3>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">Guests:</span> the registered account holders who search
                        for, book or use shortlets or accomodation services or any similar services offered on the
                        Platforms.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">Hosts:</span> the registered account holders who offer
                        accommodations, shortlets, or any similar services through the Platforms.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        {" "}
                        <span className="list--item--option">Listings</span> or{" "}
                        <span className="list--item--option">Host Services:</span> the services or offerings provided by
                        the Host through the Platforms.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">Services:</span> the services provided by the Brooi through
                        its website, App or related platform.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">User:</span> anyone who makes use of our website, Apps, and
                        services, whether or not they are a registered account holder, including but not limited to
                        Hosts and Guests.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">Website:</span> Brooi.com
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Application</h3>
                  <p className="paragraph--wrapper">This Agreement applies to the following persons:</p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">Visitors to our website.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Subscribers to Brooi newsletters, blogs, and bulletins.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Users who sign up to Brooi to open a Brooi Account to test the functionality of Brooi Services.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Users who download and use our Apps.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Acceptance and Agreement</h3>
                  <p className="paragraph--wrapper">
                    By deciding to access and use (either by computer, mobile, or other electronic devices now or
                    hereafter devised) our website and services, You:
                  </p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">
                        Confirm that You are at least 18 years old, or of the legal age stipulated in the jurisdiction
                        in which You reside.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Have the legal capacity required to enter into this Agreement.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        You acknowledge that You have carefully gone through these Terms; and
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        agree to be bound by this Agreement as well as the Privacy Policy which sets out our privacy and
                        data protection practices; any other terms provided separately to you (User) or identified on
                        our website or App as applying to User's access to and use of our Services, and additional
                        third-party terms and conditions indicated in this Agreement or other documents on our website
                        or App.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Privacy Policy</h3>
                  <p className="paragraph--wrapper">
                    In furtherance of our aim to provide you with quality service and leave you satisfied, Brooi
                    collects and processes your personal data. Brooi’s{" "}
                    <Link to="/policy">
                      <span className="link">Privacy Policy</span>
                    </Link>{" "}
                    contains some important information relating to our collection, use, storage, and disclosure of your
                    personal data/information. User agrees that Brooi may process User’s personal data/information in
                    line with Brooi’s Privacy Policy to adequately provide our Services. User further agrees that by
                    entering or uploading your personal information on our Platforms for our Services and by using these
                    Services, You permit us to make anonymized data based on personal (and non-personal) data collected
                    from User or through User's use of our Services and combine such anonymized data with that of other
                    Users to make anonymized aggregate data. We may use these anonymized data and anonymized aggregated
                    data for various business purposes, including but not limited to compiling statistical reports,
                    improving the Services, developing and improving other Brooi products and services, and distributing
                    or licensing such data to third parties with whom Brooi has a business relationship.
                  </p>
                  <p className="paragraph--wrapper">
                    <span className="lits--item--option">
                      Please read our Privacy Policy carefully for adequate information on our privacy practices.
                    </span>
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Intellectual Property Rights</h3>
                  <p className="paragraph--wrapper">
                    Except otherwise as expressly provided by this Agreement, Brooi possesses proprietary rights (either
                    by ownership or license) to the content, arrangement, layout of the website or App including the
                    Computer Code (including Source Code or Object Code). All materials on the website or App, such as
                    text, visual interfaces, graphics, logos, button icons, design, images, audio clips, compilation,
                    information, data, products, software, services, and all other elements of the Site (the
                    “Materials”), and all underlying technology utilized to provide our services including but not
                    limited to all software, copies, corrections, bug fixes, enhancements, modifications or new
                    versions, research, development and experimental development therein are owned by Brooi and are
                    protected by all relevant intellectual property and proprietary rights laws such as copyright, trade
                    secrets, trademarks, and other relevant and applicable laws.
                  </p>
                  <p className="paragraph--wrapper">
                    You may not copy, use, modify, reproduce, republish, post, transmit or distribute in any way any
                    material from our website or App including but not limited to the underlying code and software, save
                    as authorized by this agreement or as expressly authorized by us. You are only authorized to use our
                    website, App, and its contents for your personal, non–commercial use. This amounts to viewing the
                    site and printing off individual pages or sections for personal use. Please note that this paragraph
                    also applies to third-party property used by us on our website or Apps which includes but is not
                    limited to these third-party Computer Codes.
                  </p>
                  <p className="paragraph--wrapper">
                    If you subsequently suggest improvements to our websites, Apps or/and Services, through feedbacks or
                    comments, etc., and we effect these suggestions or feedbacks, Brooi Inc. reserves the rights, title,
                    and interest in these suggestions and are entitled by this Agreement to utilize all suggestions
                    without restrictions. User hereby irrevocably assigns all rights (including such moral rights)
                    thereto to Brooi. Consequently, User agrees that User is not entitled to any compensation thereof
                    from Brooi.
                  </p>
                  <p className="paragraph--wrapper">
                    Nonetheless, Brooi grants you (User) a personal, limited, revocable, non-exclusive,
                    non-transferable, non-sublicensable right and license to use our Services during the term of this
                    Agreement under the terms and conditions of this Agreement. Under this paragraph and subject to this
                    Agreement, User agrees that User shall not do the following:
                  </p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">
                        Breach, override or circumvent any authentication or security mechanisms, or circumvent any user
                        restrictions built into the App, website, or our services.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Remove or dilute or tarnish any proprietary notices, ownership labels, or marks from our
                        services.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Use our website, App, or services other than for your personal use nor in any way that violates
                        these Terms of Service.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Modify, translate, reverse engineer, decrypt, decode, disassemble, decompile, manipulate, create
                        derivative works based on Brooi’ technology to the extent the foregoing is expressly prohibited
                        by appliable laws.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Disseminate, sell, license, sublicense, market, resell, transfer, assign, distribute, rent,
                        lease, or sub-authorize any part of our services to anyone, person, or organization.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Transmit any worms, viruses, trojan horses, malware, or any other disruptive or harmful software
                        or data through User's access to and use of our Services.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Perform any actions with our services that disrupt, interferes, disturbs, destroys, or
                        unlawfully access our services, systems, records, connections, or other assets or tools or any
                        connected third-party.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Use our services or any other aspect thereof for any unlawful or fraudulent purpose or otherwise
                        in any way not authorized by this Agreement.
                      </p>
                    </li>
                  </ul>
                  <p className="paragraph--wrapper">
                    Concerning Brooi Trademarks on our website, Apps or/and Services, Brooi does not grant User the
                    rights to use, copy, reproduce or license any of Brooi’s Trademarks, graphics, typefaces, brand name
                    or color and logos anywhere in the world. Brooi's Trademarks, graphics, typefaces, brand name,
                    color, and logos should not be used in any manner or for any purpose without the express permission
                    of Alvonant Limited.
                  </p>
                </li>

                <li className="subsection-6">
                  <h3 className="header--three--wrap">Services</h3>
                  <p className="paragraph--wrapper">
                    The Platforms enable Users to publish, offer, search for and book Services for accommodation,
                    shortletting, parties, vacations, events, etc. To use the Services or any of the features on the
                    Platforms as a Host or Guest, you must register either an Host OR Guest account with Us. Please note
                    that Brooi provides the Platform and does not own, control, offer or manage any of the Listings or
                    services provided by the Host through the Platforms. By choosing and using any of the Listings,
                    Guests and Hosts enter into a contract. Brooi is not a party to these contracts. Brooi is neither an
                    insurer, agent or broker for the Hosts or Guests, except where specified in this Terms or any of our
                    policies.
                  </p>
                  <ul>
                    <li className="subsection-61">
                      <h3 className="header--three--wrap">Searching and Booking</h3>
                      <ul>
                        <li className="subsection-611">
                          <p className="paragraph--wrapper ">
                            Searches for Listings may be made through the search features provided on the Platforms by
                            using key words and filters like Host Service, travel destination, travel dates, and number
                            of guests. The outcomes of your search are based on their relevance to your search and other
                            criteria.
                          </p>
                        </li>
                        <li className="subsection-612">
                          <p className="paragraph--wrapper">
                            By booking a Listing on our Platforms, you agree to pay all related to such Listing,
                            including but not limited to the Listing prices, caution fees, taxes, service fees and other
                            applicable fees. By receiving booking confirmations or reservations, you directly enter a
                            contract with the Host for the services you booked. You also agree to comply with all the
                            terms of the reservations, including the cancellation policy and rules identified in the
                            Listing or rules provided by the Host that apply to the reservations made. You are
                            responsible for reading and understanding these rules, and requirements prior to booking a
                            Listing. Be aware that some Hosts work with a co-host or as part of a team to provide their
                            Host Services.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="subsection-62">
                      <h3 className="header--three--wrap">Reservations</h3>
                      <ul>
                        <li className="subsection-621">
                          <p className="paragraph--wrapper">
                            Reservations made by you pursuant to the Terms or any applicable policies are a limited
                            non-exclusive license to enter, occupy and use the shortlet. The Host may re-enter the
                            shortlet during your stay. Where you stay past checkout agreed with the Host at the point of
                            making reservations, the Host reserves the right to make you leave in a manner consistent
                            with applicable law. You agree not to exceed the maximum number of allowed guests.
                          </p>
                        </li>
                        <li className="subsection-622">
                          <p className="paragraph--wrapper">
                            Reservations permit you to access, attend or use the Services provided by the Host. It is
                            your responsibility to confirm that you or your guests minimum age, proficiency, fitness, or
                            other requirements. You are responsible for informing the Host of any medical or physical
                            conditions, or other circumstances that may impact your ability to participate, attend, or
                            use the Host Service. Except where expressly authorized, you may not allow any person to
                            join a Host Service unless they are included as an additional guest during the booking
                            process.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="subsection-63">
                      <h3 className="header--three--wrap">Cancellations and Refunds</h3>
                      <ul>
                        <li className="subsection-631">
                          <p className="paragraph--wrapper">
                            Cancellations of reservations (“<span className="list--item--option">Cancellations</span>”),
                            subject to the Host’s cancellation policy at the point of booking, may entitle you to a
                            refund depending on the reasons and time at which the cancellation is made. Cancellations
                            may be made, (i) after booking but before check-in; or (ii) after check-in or scheduled date
                            of arrival. Where Cancellations occur no later than 24 hours before check-in (“
                            <span className="list--item--option">Cancellation Period</span>”), Guests may be entitled to
                            a full refund of the cleaning fees and Brooi guest and service fees. Where cancellation
                            occurs after Cancellation Period and check-in, Guests may be entitled to a partial refund of
                            the Brooi guest and service fees depending on the length of the guest’s stay. Guests would
                            not be entitled to a refund of the cleaning fees where cancellation occurs after check-in.
                          </p>
                        </li>
                        <li className="subsection-632">
                          <p className="paragraph--wrapper">
                            Where the Host cancels the reservation before check-in, Brooi will assist the Guest with
                            rebooking and where this is not possible, the Guest will be automatically refunded the full
                            cleaning fees, Brooi guest and service fees.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="subsection-64">
                      <h3 className="header--three--wrap">Booking Modifications</h3>
                      <p className="paragraph--wrapper">
                        Guests and Hosts are responsible for any booking modifications they agree to make through the
                        Platforms or through our customer service representatives. Guests and Hosts agree to make
                        additional payments or taxes associated with any booking modifications made by them.{" "}
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Responsibilities</h3>
                  <p className="paragraph--wrapper">
                    Guests are responsible and bear liability for their acts or omissions or acts of omissions of
                    persons they invite or grant access to any of the Host Services. It is the Guest’s responsibility to
                    leave the shortlet in the condition that it was prior to or at the point of your arrival; or pay the
                    caution fees and any other fees that cover the damages that you or your guests may have caused to
                    the shortlet. It is the Guest’s responsibility to investigate a Host Service to determine whether it
                    is suitable for you.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Terms Relating to Hosts</h3>
                  <ul>
                    <li className="subsection-81">
                      <p className="paragraph--wrapper">
                        As a Host on the Platform, Brooi grants you the non-exclusive rights to use the Platform to
                        provide Host Services to Guests subject to the terms stated herein. As a Host, you are
                        responsible for creating Listings and determining the price, availability and the rules for your
                        Listings and ensuring that they are in accordance with the terms herein. Host must disclose all
                        rules, prices and terms of Listing in the Listing description.
                      </p>
                    </li>
                    <li className="subsection-82">
                      <p className="paragraph--wrapper">
                        Listings are required to include complete and accurate information about your Host Service, your
                        price, other charges like cleaning fees, resort fees, offline fees, and any rules or
                        requirements that apply to your Guests or Listing. It is your responsibility, as Host, to keep
                        your Listing information (including calendar availability) and content (like photos) up-to-date
                        and accurate at all times. You can only maintain one Listing per accommodation but may have
                        multiple Listings for a single property if it has multiple places to stay.
                      </p>
                    </li>
                    <li className="subsection-83">
                      <p className="paragraph--wrapper">
                        It is your responsibility, as Host, to obtain relevant insurance in respect of your Listings and
                        Host Service. You agree to comply with applicable laws and relevant contracts with third parties
                        that apply to your Listings when providing the Host Service to the Guest. Hosts shall be
                        responsible and liable for their own acts and omissions as well as the acts or omissions of
                        anyone that they permit or agents of the Hosts who are authorized to provide the Host Service
                        for the Host.{" "}
                      </p>
                    </li>
                    <li className="subsection-84">
                      <p className="paragraph--wrapper">
                        Host acknowledge and assume the entire risk arising out of your access to and use of the
                        Platform, offering Host Services, or any interaction you have with other Members whether in
                        person or online. You agree that you have had the opportunity to investigate the Platform and
                        any laws, rules, regulations, or obligations that may be applicable to your Listings or Host
                        Services and that you are not relying upon any statement of law made by Brooi.
                      </p>
                    </li>
                    <li className="subsection-85">
                      <p className="paragraph--wrapper">
                        By accepting Reservations, Hosts enter into a contract directly with Guest and have the
                        responsibility of providing the Host Service in accordance with the terms of your Listing(s).
                        Host also agrees to pay applicable charges including but not limited to Service Fees and taxes
                        for each Reservation. These applicable charges shall be deducted from the payout unless a
                        different means is agreed between Brooi and Host.
                      </p>
                    </li>
                    <li className="subsection-86">
                      <p className="paragraph--wrapper">
                        As Host, you are not an employee, agent or partner of Brooi, unless where Brooi acts as payment
                        collection agent on behalf of Host in accordance with the terms stated herein. The relationship
                        between Brooi and Host is one of an independent contractor. We do not direct or control your
                        Host Service.
                      </p>
                    </li>
                    <li className="subsection-87">
                      <h3 className="header--three--wrap">Cancellations and Refunds</h3>
                      <ul>
                        <li className="subsection-871">
                          <p className="paragraph--wrapper">
                            Hosts shall not cancel a Guest’s confirmed reservation without providing a valid reason for
                            cancellation to Brooi through the feature on the Platform. Brooi reserves the right to
                            determine whether a reason is valid and sufficient enough for cancellation.
                          </p>
                        </li>
                        <li className="subsection-872">
                          <p className="paragraph--wrapper">
                            Where a Host cancels on a Guest without a valid reason, Brooi may impose a cancellation fee
                            which shall be between 50% - 20% of the Guest and Service fees, depending on the
                            circumstance of the cancellation. Where Host cancels a confirmed reservation 48 hours or
                            less before check-in or after check-in without a valid reason, Host shall be liable to
                            payment of 50% of the Guest and Service fees for nights not stayed. Where Host cancels
                            confirmed Reservation more than 48 hours and 30 days or less before check-in, Host shall be
                            liable to payment of 25% of the Guest and Service Fees. Host shall be liable to payment of
                            10% of the Guest and Service Fees where the confirmed Reservation is canceled more than 30
                            days before check-in.
                          </p>
                        </li>
                        <li className="subsection-873">
                          <p className="paragraph--wrapper">
                            In addition to the cancellation fees mentioned above, where the Host is unable to provide a
                            valid reason, the Host may prevented from accepting other Reservations for the Listing on
                            the canceled dates. 5 consecutive cancellations of confirmed Reservations without valid
                            reason within a 6-month period may result in the Host’s account or Listing being suspended
                            or removed from the Platform by Brooi.
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li className="subsection-88">
                      <h3 className="header--three--wrap">Booking Modifications</h3>
                      <p className="paragraph--wrapper">
                        Hosts and Guests are responsible for any booking modifications they agree to make through the
                        Platforms or through our customer service representatives. Hosts and Guests agree to make
                        additional payments or taxes associated with any booking modifications made by them.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Taxes</h3>
                  <ul>
                    <li className="subsection-91">
                      <p className="paragraph--wrapper">
                        Hosts shall be responsible for determining and fulfilling their obligations under applicable
                        laws to report, collect, remit or include in their prices any applicable VAT or other taxes as
                        may be required by applicable laws.
                      </p>
                    </li>
                    <li className="subsection-92">
                      <p className="paragraph--wrapper">
                        Where Brooi serves as a payment collection agent and collects taxes on behalf of Host, Host
                        authorizes Brooi to collect and remit such taxes as withholding tax to the relevant tax
                        authority on behalf of the Host. Brooi may seek additional amounts from Users (including by
                        deducting such amounts from future payouts) in the event that the taxes collected and/or
                        remitted are insufficient to fully discharge that Users’ tax obligations, and you agree that
                        your sole remedy for taxes collected by Brooi is a refund from the relevant tax authority. You
                        acknowledge and agree that Brooi retains the right, with prior notice to affected Users, to
                        cease the collection and remittance of taxes in accordance with applicable laws.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Ratings</h3>
                  <p className="paragraph--wrapper">
                    For each Host Service provided to a Guest, Guests and Hosts shall be able to rate each other and
                    post a review of each other on the Platform in respect of the Listing and Host Service. Reviews must
                    not be discriminatory, defamatory, offensive or abusive.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Fees</h3>
                  <p className="paragraph--wrapper">
                    Brooi may charge fees to Hosts and Guests for the use of the Platform. These shall be known as
                    Platform Fees. Hosts and Guests agree that Brooi shall charge 12% of the Guest and Service Fees as
                    Platform Fees. Brooi reserves the right to change the Platform Fees at any time upon providing users
                    prior notice of any Platform Fee changes. You agree to Brooi deducting the Platform Fee through the
                    Payout Method on the Platform.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">General Conduct of Users on Platform</h3>
                  <p className="paragraph--wrapper">
                    At the point of using the Brooi Platform, you must abide by the following rules and must not help or
                    induce others to break or circumvent these rules:
                  </p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">Act with integrity and treat others with respect</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not lie, misrepresent something or someone, or pretend to be someone else.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Be polite and respectful when you communicate or interact with others
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">iv. Do not discriminate against or harass others.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not scrape, hack, reverse engineer, compromise or impair the Brooi Platform
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not use bots, crawlers, scrapers, or other automated means to access or collect data or other
                        content from or otherwise interact with the Brooi Platform.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not hack, avoid, remove, impair, or otherwise attempt to circumvent any security or
                        technological measure used to protect the Brooi Platform or Content.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not decipher, decompile, disassemble, or reverse engineer any of the software or hardware
                        used to provide the Brooi Platform.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not take any action that could damage or adversely affect the performance or proper
                        functioning of the Brooi Platform.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Only use the Brooi Platform as authorized by these Terms or another agreement with us
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        You may only use another User’s personal information as necessary to facilitate a transaction
                        using the Brooi Platform as authorized by these Terms and in accordance with our privacy policy.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not use the Brooi Platform, our messaging tools, or Users’ personal information to send
                        commercial messages without the recipient’s express consent.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        You may use Content made available through the Brooi Platform solely as necessary to enable your
                        use of the Brooi Platform as a Guest or Host.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not request, make, or accept a booking or any payment outside of the Brooi Platform to avoid
                        paying fees, taxes or for any other reason.{" "}
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not require or encourage Guests to open an account, leave a review, complete a survey, or
                        otherwise interact, with a third party website, application or service before, during or after a
                        Reservation, unless authorized by Brooi.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not engage in any practices that are intended to manipulate our search algorithm.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not book Host Services unless you are actually using the Host Services.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not use, copy, display, mirror or frame the Brooi Platform, any Content, any Brooi branding,
                        or any page layout or design without our consent.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Understand and follow the laws that apply to you, including privacy and data protection laws.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        If you provide us with someone else’s personal information, you: (i) must do so in compliance
                        with applicable law, (ii) must be authorized to do so, and (iii) authorize us to process that
                        information under our Privacy Policy.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not organize or facilitate unauthorized parties or events. You are responsible and liable for
                        any party or event during your Reservation that violates our rules for parties and events, as
                        incorporated by reference herein.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not use the name, logo, branding, or trademarks of Brooi or others without permission.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not use or register any domain name, social media handle, trade name, trademark, branding,
                        logo, or other source identifier that may be confused with Brooi branding.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not offer Host Services that violate the laws or agreements that apply to you.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Do not offer or solicit prostitution or participate in or facilitate human trafficking.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        User agrees to use our website, Apps, or services in good faith and compliance with any
                        applicable law (either local or international) and all relevant laws in User's jurisdiction.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Furthermore, User undertakes that all funds deposited or disbursed via our platforms are free
                        from fraud or fraudulent practices. User also undertakes that all information or data which are
                        required for the effective provision of our Services and are supplied by User on our platforms
                        are accurate, credible, lawful, and true.{" "}
                      </p>
                    </li>
                  </ul>
                  <p className="paragraph--wrapper">
                    <span className="list--item--option">
                      User agrees that Brooi reserves the rights to terminate any account or withdraw its services from
                      any User that violates this paragraph, paragraph 5, or/and any other part of this Agreement.
                    </span>
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Limitation of Liabilities and Disclaimer</h3>
                  <ul>
                    <li className="subsection-13-1">
                      <h3 className="header--three--wrap">Warranty Disclaimer</h3>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">
                          User expressly understands and agrees that except as expressly stated in this Agreement and
                          any other documentation that governs the use of and access to our Services or the fullest
                          extent permissible by any applicable laws, neither Brooi, its agents, employees, or officers
                          shall be responsible or liable to You for any use of or reliance on the website, Apps,
                          content, software or any disruptions to or delay in the provision of our Services. To the
                          fullest extent permissible by any applicable law, we make no express warranties or
                          representations as to the quality and accuracy of our content, website, Apps, and Services.
                          Our website, Apps, any materials downloaded therefrom, or any third party materials, are
                          provided on an ” as is” and “as available” basis, and You will be solely responsible for any
                          damage to your computer system or loss of data that results from the download, or access of
                          any material obtained through the use of our website, Apps or any other functionalities of
                          thereof, or losses or damages (financial or otherwise) resulting from your use of our Website,
                          Apps, the information thereon, any materials downloaded therefrom, or any activity arising
                          from the use of our Website, Apps, the information thereon or any materials downloaded
                          therefrom.
                        </span>
                      </p>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">
                          We and our third-party providers/licensors do not make any representations as to the accuracy,
                          timeliness, comprehensiveness, completeness, quality, reliability, currency, error-free
                          nature, compatibility, security, data loss, non-interference with or non-infringement of any
                          intellectual property rights, or fitness for a particular purpose of the website, Apps,
                          content, and services to the fullest extent permitted by any applicable law.
                        </span>
                      </p>
                    </li>
                    <li className="subsection-13-2">
                      <h3 className="header--three--wrap">Limitation of Liabilities</h3>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">
                          User expressly understands and agrees that except as expressly stated in this Agreement and
                          any other documentation that governs the use of and access to our Services or the fullest
                          extent permissible by any applicable laws, neither Brooi, its agents, employees, or officers
                          shall at any time be responsible or liable to You or anyone else for any loss or injury
                          whatsoever (including, without limitation, direct, indirect, incidental and consequential,
                          special, punitive or any similar damages, personal injury/wrongful death, lost profits, or
                          damages resulting from lost data or business interruption) resulting from the use or inability
                          to use our website, Apps, or their content, whether based on warranty, contract, tort
                          (including negligence), statute or any other legal theory, and whether or not Brooi has been
                          informed of the possibility of such damage. You hereby waive any and all claims against Brooi
                          and the above listed, arising out of your use of our website, Apps, and the information
                          available thereon. You understand and agree that Brooi will not be responsible for any
                          offensive conduct of any user or third-party organization, in any way connected with your
                          interaction with our website or Apps or their content.
                        </span>
                      </p>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">
                          Other than for the types of liability we cannot limit by law you agree that the aggregate
                          liability of Brooi to you for any and all claims arising out of or relating to the use of or
                          any inability to use the site (including any materials or content available through the site)
                          or otherwise under these terms, whether in contract, tort or otherwise, shall not exceed one
                          hundred dollars (USD 100.00). This liability is complete and exclusive.
                        </span>
                      </p>
                      <p className="paragraph--wrapper">
                        <span className="list--item--option">
                          Each provision of these terms that provides for a limitation of liability, disclaimer of
                          warranties, or exclusion of damages is to allocate the risks under these terms between the
                          parties. This allocation is an essential element of the basis of the bargain between the
                          parties. Each of these provisions is severable and independent of all other provisions of
                          these terms. The limitations under this section will apply even if any limited remedy fails of
                          its essential purpose.
                        </span>
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Interruption of Services</h3>
                  <p className="paragraph--wrapper">
                    Brooi may occasionally undergo certain maintenance or/and modification of our website. This may
                    result in the temporary unavailability of our website. During this temporary unavailability, Brooi
                    or our representatives shall not be liable for any losses, injury, damages arising from such
                    interruptions of the functioning of our website
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Third-Party Services</h3>
                  <p className="paragraph--wrapper">
                    Our Services may sometimes include links to or direct User's attention to third-party websites,
                    services, features, or products. The inclusion of such links does not imply an association, support,
                    endorsement, consent, examination, or authentication by Brooi of such third party or their services
                    (including but not limited to any content made available within such third-party services). Brooi
                    shall not be liable for the information and content contained in any Third-Party Services or for
                    User's use of or incapacity to use such Third-Party Services, and Brooi expressly disclaims any
                    liability for them. Access to any Third-Party Services is at User's risk, and User must bear in mind
                    that Third-Party Services are governed by their own terms of service and Privacy Policys which may
                    be different from those of Brooi’. User agrees to comply with third-party’s terms of service and
                    Privacy Policys. User agrees that the third party, and not Brooi, will be entirely liable for the
                    delivery and performance of the respective Third-Party Services.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Registrations, Accounts, and Passwords </h3>
                  <p className="paragraph--wrapper">
                    To use our services, you may be required to complete an online registration or via the App, to
                    create a Brooi Account upon providing the required information. Some information required to create
                    a Brooi Account may include but is not limited to: (Please insert the info you may require of them).
                    Please note that any or all information provided by User under this paragraph shall be credible,
                    current, accurate, lawful, and true. User shall also update such registration information where such
                    information User hitherto provided changes.
                  </p>
                  <p className="paragraph--wrapper">
                    On registration, User shall choose a username and password. User undertakes to be responsible for
                    ensuring the confidentiality and safety of such passwords from third parties. User undertakes to
                    promptly notify Brooi of any breach or unauthorized use of your password or username.
                  </p>
                  <p className="paragraph--wrapper">
                    Where User logs in to their account on our website, you are advised to log out of your account at
                    the end of every session. Also, do not leave your logged-in account unattended for any period. We
                    shall not be liable for any losses, damages, injury that arises from your negligence in this regard
                    or failure to log out at the end of such session.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Advertising</h3>
                  <p className="paragraph--wrapper">
                    Our Services (either on our websites or Apps) may occasionally involve advertisements for or links
                    to third-party websites, products, or services (third-party Ads). We will not be held liable for the
                    availability of these third-party Ads, images or messages, or other materials. We shall not be
                    liable for any errors, contents, or omission in these Third-party Ads nor are we liable for any
                    losses, injury, or damages arising from your participation with or usage or reliance on these
                    third-party Ads, including but not limited to the websites, products, services offered by such third
                    parties.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Bank Account Verification</h3>
                  <p className="paragraph--wrapper">
                    By creating an account with us via our website or App and using our Services, you agree that Brooi
                    may retrieve your account transaction history, balance information, or/and other information
                    maintained by the financial institution that you choose during your registration with us and during
                    Payout. You further authorize Brooi to charge your Payment Method (including charging more than one
                    payment method) either directly or indirectly, for all fees due (including any applicable taxes) in
                    connection with your Brooi account. By accepting these Terms, you also agree that you are
                    responsible for securing your passwords and usernames for this third-party account information
                    including keeping these passwords and usernames up to date.{" "}
                  </p>
                  <p className="paragraph--wrapper">
                    You acknowledge that any Third-Party Account Information that is entered by you and displayed
                    through the Payout Method and on the Platform will be accurate.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Security</h3>
                  <p className="paragraph--wrapper">
                    In a bid to provide optimal services to you, we shall employ effective and the best cybersecurity
                    measures necessary to protect your information on our platforms from a breach, and also to ensure
                    smooth operations on our websites, Apps, or other platforms.
                  </p>
                  <p className="paragraph--wrapper">
                    Brooi shall put security measures in place to identify and mitigate security risks to technology
                    assets that process, or store User data on our platforms. Some of these include adhering to industry
                    standards like NIST and ISO. Other measures that will be baked into our app and processes include:
                  </p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">Data pseudonymization.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Multi-Factor authentication to prevent brute-forcing.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Device Fingerprinting in addition to geofencing.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Biometric authentication.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Leverage on cloud-based servers and security and using VPCs.</p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Counter Business Logic flaws by enforcing the principle of least privilege.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Ensuring Input validation and Output Encoding where possible.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Carrying out Threat modeling and ensure Shift-Left Secure CI/CD process.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Indemnity</h3>
                  <p className="paragraph--wrapper">
                    <span className="list--item--option">
                      You hereby agree to indemnify Brooi, its agents, officers, affiliates, directors, shareholders,
                      employees, and licensees or assigns for any and all claims, law-suits, damages, injury,
                      liabilities, losses or/and expenses (including but not limited to legal fees) connected to any
                      acts done by you relating to your use of our Services and Platform that arises from claims against
                      us by any third-party.{" "}
                    </span>
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Enforcement</h3>
                  <p className="paragraph--wrapper">We reserve the rights at all times to:</p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">
                        Investigate any suspected breaches of our website or App security or its information technology
                        or other systems or networks.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Investigate any suspected breaches of this Agreement or any additional terms, conditions, or
                        rules posted on our website, Apps, or any of our other platforms that are related to our
                        Services.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Involve and cooperate with law enforcement agents or authorities in investigating such matters.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Prosecute violators of this Agreement in accordance with applicable laws.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Detect, prevent, or address fraud, security, and technical issues.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Protect the rights, property, and safety of our Users and the public in general.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Respond to all or any User support requests.</p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h3 className="header--three--wrap">Subsequent Changes to this Agreement and Services </h3>
                  <p className="paragraph--wrapper">
                    We reserve the rights at any time to occasionally effect changes in, update, or amend this
                    Agreement, or the functionality of our Services (with or without notice) except where such amendment
                    is prohibited by any applicable law. Brooi may, however, notify you by posting an updated Terms of
                    Service on our website or App, where we effect a change in our Terms of Service. We recommend that
                    you periodically review our Terms of Service on our website or App, taking note of the date it was
                    "Last Updated". Bear in mind that continued use of our Website, Apps, or services shall constitute
                    consent to be bound by the subsequent changes in our Terms of Service.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">App Updates and Upgrades</h3>
                  <p className="paragraph--wrapper">
                    User agrees to receive updates or/and upgrades to our App automatically through the internet without
                    providing consent each time. <span className="list--item--option">Note</span>that our App shall:
                  </p>
                  <ul>
                    <li>
                      <p className="paragraph--wrapper">
                        Collect personal information as stated in our{" "}
                        <Link to="/policy">
                          <span className="link">Privacy Policy.</span>
                        </Link>
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">
                        Cause your device to automatically communicate with our servers to deliver the functionality
                        described in the App description or via new features we introduce as well as record usage
                        metrics.
                      </p>
                    </li>
                    <li>
                      <p className="paragraph--wrapper">Affect preferences or data stored on your device.</p>
                    </li>
                  </ul>
                  <p className="paragraph--wrapper">
                    User agrees that Brooi shall not be liable for any update that affects how the App operates where
                    such is caused by your device or equipment by not supporting the update. In accordance with privacy
                    practices, you may decide to withdraw the consent given under this paragraph at any time by
                    contacting Brooi.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Force Majeure</h3>
                  <p className="paragraph--wrapper">
                    User agrees to relieve Brooi of any liabilities that may arise from Brooi’ delay in performance or
                    non-performance under this Agreement which is caused by an event beyond Brooi’ reasonable control
                    (i.e. Acts of God, policies issued by the State, government institutions, strikes, war, pandemics,
                    terrorism, civil unrests, etc.).{" "}
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Termination</h3>
                  <p className="paragraph--wrapper">
                    On accepting these Terms by User, you agree that this Agreement commences thereon and remains in
                    force until either party chooses to terminate this Agreement under this Agreement or any applicable
                    law. You agree that Brooi reserves the discretionary rights, with or without notice to suspend or
                    terminate this Agreement with you or suspension or termination of your Brooi Account or use of or
                    access to our website, Apps or/and services, where Brooi has reasons to believe that you are in
                    violation or acted inconsistently with this Agreement. Consequently, you agree that Brooi shall not
                    be liable to you or any third-party for losses, damages, or injury arising from the suspension or
                    termination of this Agreement with you or suspension or termination of your Brooi Account or use of
                    or access to our website, Apps or/and services.
                  </p>
                  <p className="paragraph--wrapper">
                    User may also terminate this agreement by deleting your Brooi Account or requesting that Brooi erase
                    your Brooi Account. Such termination under this paragraph and pursuant to this Agreement shall not
                    affect provisions of this Agreement that is expressly or by implication required to come into force
                    or continue in force after such termination.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Governing Laws and Jurisdiction</h3>
                  <p className="paragraph--wrapper">
                    Regardless of where the website or App is accessed or used, the User agrees that this Agreement
                    shall be governed by and construed under the Laws of the Federal Republic of Nigeria, excluding any
                    rules of private international law or any conflict of laws that would lead to the application of any
                    other laws.
                  </p>
                  <p className="paragraph--wrapper">
                    Whatsoever disputes that may arise from this Agreement, actions shall be filed and adjudicated in
                    courts located in the Federal Republic of Nigeria. Irrespective of the foregoing, Brooi reserves the
                    right to institute actions against you in competent courts in the jurisdiction where you reside or
                    are located to seek injunctive reliefs against you, obtain a judgment against you, where a judgment
                    in a Nigerian court may not be enforced by the jurisdiction in which you reside in or are located.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Arbitration</h3>
                  <p className="paragraph--wrapper">
                    Notwithstanding the foregoing, where a dispute arises from this Agreement, such dispute or claim
                    shall first be resolved by binding arbitration under the Arbitration and Conciliation Act, Cap A19,
                    LFN 2004 or its amendments thereof, before it goes to litigation. Such arbitration as contemplated
                    under this agreement shall be presided over by one (1) arbitrator appointed by both parties in
                    accordance with the Arbitration and Conciliation Act, Cap A19, LFN 2004 or its amendments thereof.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Severability</h3>
                  <p className="paragraph--wrapper">
                    If any provision of this Agreement shall be invalid, illegal or unenforceable in any respect
                    (whether against all or only one of the Parties), such a provision shall be considered separate and
                    severable from the remaining provisions of this Agreement, and the validity, legality or
                    enforceability of any of the remaining provisions of this Agreement shall not be affected or
                    impaired by such provision in any way and the remainder of the provision in question shall remain in
                    full force and effect.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Waiver</h3>
                  <p className="paragraph--wrapper">
                    Brooi failure to exercise or enforce any of our rights or provisions under this Agreement shall not
                    constitute a waiver of such rights or provision, which will still be available to Brooi.
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Assignment</h3>
                  <p className="paragraph--wrapper">
                    User agrees not to transfer or assign User's rights or obligations under these Terms without priorly
                    notifying Brooi and Brooi giving written consent to you. Notwithstanding the foregoing, Brooi
                    reserves the discretionary rights to assign or transfer this Agreement to an affiliate, company,
                    successor, or third-party without notifying the User.{" "}
                  </p>
                </li>
                <li>
                  <h3 className="header--three--wrap">Contact</h3>
                  <p className="paragraph--wrapper">
                    Brooi is the property of Alvonant Limited. which is resident in Lagos, Nigeria. If you have any
                    questions, comments, or feedback concerning these Terms of Service, kindly contact us at @info
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default terms
